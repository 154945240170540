:root {
	//Fonts
	--inter: 'Inter', sans-serif;
	--poppins: 'Poppins', sans-serif;
	--poppins-semi-bold: 'PoppingsSemiBold', sans-serif;

	--purple1: #f9f0ff;
	--purple2: #efdbff;
	--purple4: #b37feb;
	--purple6: #722ed1;
	--purple8: #391085;

	--neutral5: #1f2431;
	--neutral4: #535969;
	--neutral3: #8a8e9c;
	--neutral2: #d5d8e1;
	--neutral1: #f2f6f8;
	--neutral0: #ffffff;

	--orange2: #ffe7ba;
	--orange4: #ffc069;
	--orange7: #d46b08;
	--orange9: #873800;

	--green2: #d9f7be;
	--green4: #95de64;
	--green9: #135200;
}
