@font-face {
	font-family: 'Poppins';
	src: local('PoppingsLight'),
		url('./../assets/fonts/Poppins/PoppinsLight.ttf') format('truetype');
}
@font-face {
	font-family: 'PoppingsBold';
	src: local('PoppingsBold'),
		url('./../assets/fonts/Poppins/PoppinsBold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'PoppingsSemiBold';
	src: local('PoppingsSemiBold'),
		url('./../assets/fonts/Poppins/PoppinsSemiBold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'PoppingsMedium';
	src: local('PoppingsMedium'),
		url('./../assets/fonts/Poppins/PoppinsMedium.ttf') format('truetype');
	font-weight: bold;
}

/* html {
	overflow-x: hidden;
}

body {
	font-family: 'PoppingsLight', sans-serif;
	overflow-x: hidden;
	background-color: #ffffff !important;
	margin: 0px;
	padding: 0px;
} */

@import './base/root';

html {
	font-family: var(--poppins);
}

body {
	margin: 0px;
}
